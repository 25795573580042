import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import { ISynologyFileStationApi } from './provider/provider-api';
import { NasLocalFileStationFileProvider } from './provider/nas-local-provider.api';
import { Button, Form, InputGroup, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile, faDownload, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FixedSizeList as List } from 'react-window';

const basePath = "/FILES";  
const provider: ISynologyFileStationApi = new NasLocalFileStationFileProvider();

type ErrorProps = {
  error: string;
}

const Error: React.FC<ErrorProps> = ({ error }) => {
  if (!error) {
    return null;
  }
  return (
    <div className="alert alert-danger" role="alert">
      {error}
    </div>
  )
}

interface LoadingProps {
  loading: boolean;
}

const Loading: React.FC<LoadingProps> = ({ loading }) => {
  if (!
    loading) {
    return null;
  }
  return (
    <div className="spinner-border" role="status"/>
  )
}


interface FileItem {
  name: string;
  isDirectory: boolean;
  path: string;
  modified : string|undefined;
}

interface BrowserViewProps {
  visible: boolean;
}

const BrowserView: React.FC<BrowserViewProps> = ({ visible }) => {
  const [error, setError] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [files, setFiles] = useState<Array<FileItem>>([]);
  const [visibleFiles, setVisibleFiles] = useState<Array<FileItem>>([]);
  const [folderPath, setFolderPath] = useState<string>(basePath);

  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);

  // Function to handle the actual searching/filtering
  const filterFiles = React.useCallback((searchValue: string) => {
    if (searchValue.length === 0) {
      setVisibleFiles(files);
      return;
    }
    const searchFiles = files.filter((file) => file.name.toLowerCase().includes(searchValue.toLowerCase()));
    setVisibleFiles(searchFiles);
  }, [files]);

  const navigateTo = useCallback((path: string) => {
    if (loadingFiles) {
      return;
    }
    setError('');
    setLoadingFiles(true);
    provider.listAllFilesAtAsync(path).then((response) => {
      if (response.success) {
        const files = response.data.files.map((file) => {
          return {
            name: file.name,
            isDirectory: file.type === 'dir',
            path: file.path,
            modified: file.modified
          };
        });
        setFiles(files);
        setVisibleFiles(files);
      }
    }).catch((error) => {
      setError('Failed to list files');
    }).finally(() => {
      setLoadingFiles(false);
    });
  }, [loadingFiles]);


  useEffect(() => {
    if (visible) {
      navigateTo(folderPath);
    }
  }, [folderPath,visible]);

  useEffect(() => {
    filterFiles(search);
  }, [search,filterFiles]);

  useEffect(() => {
    if (error.length === 0) return;
    setFiles([]);
    setVisibleFiles([]);
  }, [error]);


  const navigateToFolder = (path: string) => {
    const newPathWithSlash = folderPath.endsWith('/') ? folderPath + path : folderPath + '/' + path;
    setFolderPath(newPathWithSlash);
    console.log('Navigating to', newPathWithSlash);
  }

  const goBack = () => {
    setError('');
    if (folderPath === basePath) {
      return;
    }
    var newPath = folderPath.split('/');
    if(folderPath.endsWith('/')){
      newPath.pop();
    }
    newPath.pop();

    var newFilePath = newPath.join('/');
    if (newFilePath === '') {
      newFilePath = '/';
    }
    console.log('New path', newFilePath)
    setFolderPath(newFilePath);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const downloadFile = (path: string, fileName: string) => {
    const newFilePath = folderPath.endsWith('/') ? folderPath + path : folderPath + '/' + path;
    setError('');
    provider.downloadFileAsync(newFilePath,fileName).then((response) => {
      if (response) {
        console.log('Downloaded file');
      }
    }).catch((error) => {
      console.error('Failed to download file', error);
      setError('Failed to download file');
    });
  }

  const GetFileName = (file: FileItem) : string => { 
    if(!file.isDirectory) return file.name;
    return file.name + (file.modified !== undefined && file.modified.length > 0 ? ' - (' + file.modified + ")" : '');
  }

  if (!visible) {
    return null;
  }

  return (
    <div className="p-3">
      <div className="d-flex  mb-3 gap-3">
        <Button variant="outline-primary" onClick={goBack} className="me-2">
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </Button>
        <InputGroup className="flex-grow-1">
          <Form.Control
            type="text"
            placeholder="Search files..."
            value={search}
            onChange={onSearchChange}
          />
        </InputGroup>
      </div>

      <div className="mb-3">
        <h3>{folderPath}</h3>
      </div>

      <Error error={error} />
      <Loading loading={loadingFiles} />
      {
        !loadingFiles && (
          <List
            height={500}
            itemCount={visibleFiles.length}
            itemSize={50}
            width={'100%'}
          >
            {({ index, style }) => {
              const file = visibleFiles[index];
              return (
                <ListGroup.Item
                  key={file.path}
                  style={style}
                  className="d-flex justify-content-between align-items-center">

                  <div className="d-flex align-items-center" style={{ cursor: file.isDirectory ? 'pointer' : 'default' }} onClick={() => file.isDirectory ? navigateToFolder(file.path) : null}>
                    <FontAwesomeIcon icon={file.isDirectory ? faFolder : faFile} className="me-2" />
                    {GetFileName(file)}
                  </div>
                  {!file.isDirectory && (
                    <Button variant="outline-secondary" size="sm" onClick={() => downloadFile(file.path, file.name)}>
                      <FontAwesomeIcon icon={faDownload} /> Download
                    </Button>
                  )}

                </ListGroup.Item>
              );
            }}
          </List>
        )
      }
    </div>
  );
};


function App() {

  return (
    <div className="App p-3">
      <BrowserView visible={true}/>
    </div>
  );
}

export default App;


import { ISynologyFileStationApi } from './provider-api';
import { LoginResponse, ListFilesResponse, FileType } from './provider-model';

type FileNameList = string[];

interface CarReleaseDates {
  [key: string]: string; 
}

export class NasLocalFileStationFileProvider implements ISynologyFileStationApi
{
    getInfoAsync(): Promise<string> {
        return Promise.resolve('');
    }

    loginAsync(account: string, passwd: string): Promise<LoginResponse> {
        return Promise.resolve({ success: true, data: { sid: '1234' } });
    }

    async listAllFilesAtAsync(folderPath: string): Promise<ListFilesResponse> {
        const files = await this.getFileNames(folderPath)
        const fileVersionsJson : CarReleaseDates|undefined = await this.getFileVersions();

        const fileTypes: FileType[] = files.map((file) => {
            const isDir = file.endsWith('/');
            const cleanName = file.replace('/', '');
            const modified = fileVersionsJson ? fileVersionsJson[cleanName] : undefined;
            return {
                name: file,
                type: isDir ? 'dir' : 'file',
                path: file,
                modified: modified
            };
        });
        return Promise.resolve({ success: true, data: { files: fileTypes } });
      }

      async downloadFileAsync(path: string, savePath: string): Promise<boolean> {
        // Handle the response stream accordingly (e.g., save to disk in Node.js, trigger browser download)
        const link = document.createElement('a');
        link.href = path;
        // set name 
        link.setAttribute('download', savePath);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true;
    }

    private getFileVersions = async (): Promise<any> => {
      try {
        const response = await fetch("/files.json");
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json(); // Assuming the file contains JSON
        return data;
      } catch (error) {
          console.error("Could not load the file content:", error);
          return '';
      }
    }

    private getFileNames = async ( path : string ): Promise<FileNameList> => {
      try {
        const response = await fetch(path);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const text = await response.text();
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(text, 'text/html');
        const preList = htmlDoc.getElementsByTagName('pre')[0].getElementsByTagName('a');
        const fileNames: FileNameList = [];
        for (let i = 1; i < preList.length; i++) {
          fileNames.push(preList[i].innerHTML);
        }
        // filter only files and directories
        const filteredFileNames = fileNames.filter((fileName) => {
            return fileName !== 'Name' 
                && fileName !== 'Last modified'
                && fileName !== 'Size' 
                && fileName !== 'Description' 
                && fileName !== 'Parent Directory' 
                && fileName !== "files.json";
        });
        return filteredFileNames;
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return [];
      }
    };
    
    
}